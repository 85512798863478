<template>
  <div>
    <v-main>
      <v-row class="login-container d-flex justify-center">
        <v-col class="image-container hidden-sm-and-down" md="8"></v-col>
        <v-col
          class="d-flex flex-column justify-center align-center"
          md="4"
          sm="6"
        >
          <v-img
            :src="logoLogin"
            max-height="100"
            max-width="300"
          ></v-img>
          <h3 class="text-h5 primary--text">Welcome</h3>

          <form
            @submit.prevent="login({ route: $route, user })"
            class="w-60 mt-5"
            v-if="!detectMobile"
          >
            <v-main>
              <v-row class="d-flex justify-center">
                <v-col>
                  <v-text-field
                    v-model="user.email"
                    :rules="emailRules"
                    :label="$t('labels.email')"
                  ></v-text-field>

                  <v-text-field
                    v-model="user.password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :label="$t('labels.password')"
                    @click:append="show = !show"
                  ></v-text-field>
                  <div class="row d-flex justify-end">
                    <router-link :to="{ name: 'forgotPassword' }">
                      <span>Forgot password? </span>
                    </router-link>
                  </div>
                  <v-row class="d-flex justify-center mt-10">
                    <v-btn
                      color="primary"
                      dark
                      class="submit-button"
                      :rounded="true"
                      large
                      type="submit"
                    >
                      {{ $t("buttons.login") }}
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-main>
          </form>
          <form
            @submit.prevent="mobileLogin({ route: $route, user })"
            class="w-60 mt-5"
            v-if="detectMobile"
          >
            <v-main>
              <v-row class="d-flex justify-center">
                <v-col>
                  <v-text-field
                    v-model="user.email"
                    :rules="emailRules"
                    :label="$t('labels.email')"
                  ></v-text-field>

                  <v-text-field
                    v-model="user.password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :label="$t('labels.password')"
                    @click:append="show = !show"
                  ></v-text-field>
                  <div class="row d-flex justify-end">
                    <router-link :to="{ name: 'forgotPassword' }">
                      <span>Forgot password? </span>
                    </router-link>
                  </div>
                  <v-row class="d-flex justify-center mt-10">
                    <v-btn
                      color="primary"
                      dark
                      class="submit-button"
                      :rounded="true"
                      large
                      type="submit"
                    >
                      {{ $t("buttons.login") }}
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-main>
          </form>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
import { mapActions } from "vuex";
let mobile = require("is-mobile");
const logoLogin = require(process.env.VUE_APP_LOGIN_LOGO);
export default {
  data() {
    return {
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      show: false,
      user: {
        email: "",
        password: "",
      },
      detectMobile: false,
      logoLogin: logoLogin,
    };
  },
  methods: {
    ...mapActions("auth", ["login", "mobileLogin"]),
    mobileDetect() {
      this.detectMobile = mobile({ tablet: true });
    },
  },
  mounted() {
    this.mobileDetect();
  },
};
</script>
